<script>
import Layout from '@/views/layouts/main3'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'
import i18n from '@/i18n'
import Swal from 'sweetalert2'
import global_func from '@/global_function'
// bank
import 'ol/ol.css'
import * as ol from 'ol'
import * as layer from 'ol/layer'
import * as source from 'ol/source'
import * as proj from 'ol/proj'
import * as geom from 'ol/geom'
import * as style from 'ol/style'
// import Overlay from 'ol/Overlay'
import LayerSwitcher from 'ol-layerswitcher'
import PlanDetail from '@/components/modal/plans_detail'
import ModalCompany from '@/components/modal/company'
import ModalProof from '@/components/modal/proof_of_carrier'
import rhm from '@/assets/js/routing_here_map.js'

// bank - realtime
// import dataTest from './realtime'
//  this.axios.get(api).then(response => {
export default {
  page: {
    title: i18n.t('tracking_carrier.title'),
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar, PlanDetail, ModalCompany, ModalProof},
  data() {
    let lang = localStorage.getItem('account_lang')
    let user = localStorage.getItem('users')
    var lang_obj = i18n.t('tracking_carrier')
    return {
      // tracking truck
      group_overlay_feature: null,group_base: null,
      star_flag:false,
      fv_flag:false,
      count:0,
      data_flag:false,
      group_id:null,
      img_marker: null,
      select_items:[],
      select_items2:[],
      click_select_items:[],
      marker_icon:null,
      time_set:null,
      //general
      account_lang: lang,
      account_data: JSON.parse(user),
      check_lang: true,
      lang: lang_obj,
      index_row_plan: null,
      url_params_search_flag: true,

      active_tab: 0,
      search_tracking: '',
      sort_by_plans_status: '0',
      sort_by_plans_code: '',

      plan_transition: false,
      truck_transition: false,
      //MAP VARIABLE
      map: null,
      pinPointLayer: null,
      pinPointSource: null,
      directions_data: null,
      directionSource: null,
      directionLayer: null,
      base_layer : null,

      //MASTER DATA
      dataTruckType: [],
      dataGoodsType_data: [],
      data_service_etc: [
        {
          service_etc_id: 1,
          name_th: 'พนักงานช่วยขนของ',
          name_en: 'พนักงานช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 2,
          name_th: 'พนักงานเพิ่มเติม',
          name_en: 'พนักงานเพิ่มเติม2',
          service_sel_status: '',
        },
        {
          service_etc_id: 3,
          name_th: 'บริการพาเลทรองพื้น',
          name_en: 'บริการพาเลทรองพื้น2',
          service_sel_status: '',
        },
        {
          service_etc_id: 4,
          name_th: 'อุปกรณ์ช่วยขนของ',
          name_en: 'อุปกรณ์ช่วยขนของ2',
          service_sel_status: '',
        },
        {
          service_etc_id: 5,
          name_th: 'อื่นๆ',
          name_en: 'อื่นๆ2',
          service_sel_status: '',
        },
      ],

      raw_data_of_plan: [],
      data_plan:[],
      p_of_plan_data: null, //POINTER OF PLAN DATA

      data_truck:[],
      
      plan_data_of_transition: null,
      truck_data_of_transition: null,

      arrlocation:[
        {
          name:'แขวงบางนาหนือ เขตบางนา กรุงเทพมหานคร',
          date_appointment: '2021-08-23 15:30:00',
        },
        {
          name:'แขวงบางนาหนือ เขตบางนา กรุงเทพมหานคร',
          date_appointment: '2021-08-23 14:30:00',
        },
        {
          name:'แขวงบางนาหนือ เขตบางนา กรุงเทพมหานคร',
          date_appointment: '2021-08-23 13:30:00',
        },
      ],
      subscribe_data : [],  
    }
  },
  watch: {
      
  },
  async mounted() {
    await this.getTruckType()
    await this.getDataGoodsType()
    await this.getTracking()
    // this.makePlanRow()
    // this.makeTruckRow()
    await this.arrBaseLayer()
    await this.initialMap()
    await this.getSubscribeData()
  },
  methods: {
    updateStar(val){
      // console.log(val)
      // console.log(val)
      // var that = this
      let mode = 'unsub'
      if(val.star_flag == '0') {
        mode = 'sub'
      }

      if(this.p_of_plan_data.star_flag == '0') {
        this.p_of_plan_data.star_flag = '1'
      } else {
        this.p_of_plan_data.star_flag = '0'
      }

      service
        .postService('updateSubscribe', {
          account_data : this.account_data,
          provider_id : val.id_member_goods_owner,
          mode : mode,
        })
        .then(rp => {
          if(rp.code == '200'){
            this.getSubscribeData()
            // setTimeout(() => {
            //   that.getPlansTracking()
            // }, 100)
          }
        })
    },
    update_favorite(option,type){
      if(type != 'in'){
        service
          .postService('setBookmarkPlan', {
            mode:'carrier',
            id_carrier_tracking : option.rawData.id_carrier_tracking,
          })
          .then(rp => {
            if(rp.code == '200'){
              this.getTracking()
            }
          })
      }else{
        this.fv_flag = !this.fv_flag
        service
          .postService('setBookmarkPlan', {
            mode:'carrier',
            id_carrier_tracking : option.rawData.id_carrier_tracking,
          })
          .then(rp => {
            if(rp.code == '200'){
              this.getTracking()
            }
          })
      }
     
    },
    clearData(){
      clearInterval(this.time_set)
      this.marker_s.clear()
      this.map.removeLayer(this.marker_icon)
    },
    // eslint-disable-next-line no-unused-vars
    async startRealtime(val){
      clearInterval(this.time_set)
      await this.dataRealtime(val)
      this.time_set = setInterval(async() => {
        await this.dataRealtime(val)
      }, 30000)
    },
    async dataRealtime(val) {
      try {
        // let api = await this.axios.post('https://gps.dtc.co.th:8099/getRealtimeData',{
        // let api = await this.axios.post('http://devweb.dtcgps.com:8087/getRealtimeData',{
        //   'api_token_key': 'KSYZSP24XLC3RX3BEPZW72GV1J9QAGMWNHUM89T765TRYE1DUKLB4FJ8HFD5QC6A',
        //   'gps_list': [this.click_select_items],
        // },
        // {
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },  
        // },
        // )
        // console.log('api',api)
        // this.count = this.count + 1
        // let api = {status: 200}
        // console.log(this.count)
        // console.log(val)

        service.postService('getTrackingVehicleDTC', {
          account_lang: this.account_lang,
          member_data: this.account_data,
          blackbox_id: this.click_select_items,
          id_member_carrier: val.id_member_carrier,
        }).then( rp => {
          if(rp.code == '200'){
            // จริง
            // let lat = api.data.data[0].lat
            // let lon = api.data.data[0].lon
            // test
            let realtime = []
            realtime = rp.data[0]
            // realtime = dataTest.realtime_test
            // let lat = parseFloat(realtime[this.count].split(',')[0]) 
            // let lon = parseFloat(realtime[this.count].split(',')[1])
            // let lat = parseFloat(realtime.lat) 
            // let lon = parseFloat(realtime.lon)

            if(realtime != undefined) {
              let lat = parseFloat(realtime.r_lat) 
              let lon = parseFloat(realtime.r_lon)

              // console.log(realtime)
              // console.log('lat : ',lat)
              // console.log('lon : ',lon)
         
              var new_point = new ol.Feature({
                geometry: new geom.Point(proj.fromLonLat([lon, lat])),
              })
              if(this.group_id == 1){
                this.img_marker = require('../../../assets/images/truck_img/4_wheels.png')
              }else if(this.group_id == 2){
                this.img_marker = require('../../../assets/images/truck_img/6_wheels.png')
              }else if(this.group_id == 3){
                this.img_marker = require('../../../assets/images/truck_img/10_wheels.png')
              }else if(this.group_id == 4){
                this.img_marker = require('../../../assets/images/truck_img/trailer2.png')
              }else if(this.group_id == 5){
                this.img_marker = require('../../../assets/images/truck_img/trailer.png')
              }else if(this.group_id == 6){
                this.img_marker = require('../../../assets/images/truck_img/tow_truck.png')
              }
              new_point.setStyle(
                new style.Style({
                  image: new style.Icon({
                    scale: 0.2,
                    anchor: [0.5, 1],
                    src: this.img_marker,
                  }),
                }),
              )
              // if (this.marker_icon != null) {
          
              // }
              this.marker_icon = new layer.Vector({
                source: new source.Vector({
                  features: [new_point],
                }),
              })
              this.map.removeLayer(this.marker_icon)
              // this.map.addLayer(this.marker_icon)

              var new_marker = new ol.Feature({
                geometry: new geom.Point(proj.fromLonLat([lon, lat])),
                data:val,
              })
              // check location index for select icon pinpoint
              //var pinpoint = this.src
              var pinpoint = this.img_marker
              //set style of point
              new_marker.setStyle(
                new style.Style({
                  image: new style.Icon({
                    // color: 'red',
                    crossOrigin: 'anonymous',
                    scale: 0.3,
                    anchor: [0.5, 1],
                    anchorOrigin: 'top-left',
                    src: pinpoint,
                  }),
                }),
              )

              // this.pinPointSource.addFeature(new_marker)

          
              this.marker_s.clear()
              this.marker_s.addFeature(new_marker)
          
              // this.map.getView().animate({
              //   center: proj.fromLonLat([lon, lat]),
              //   zoom: 10,
              // })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }
        
    },
    getTruckType() { // get ประเภทรถบรรทุก
      return new Promise((resolve)=>{
        service.postService('getTruckType').then(rp => {
          // console.log(rp)
          this.dataTruckType = rp.data
          resolve(true)
          // console.log(this.dataTruckType)
        })
      })
      
    },
    getTracking(){
      var that = this
      service.postService('getCarrierTracking', {
        user_data: this.account_data,
        sort_by_plans_status: this.sort_by_plans_status,
        plan_code: this.sort_by_plans_code,
      }).then( rp => {
        // console.log(rp.data)
        that.raw_data_of_plan = rp.data
        this.makePlanRow()
        //this.plans_offer_raw_data = rp.data.plans_offer_data
        //that.createRowOffer()
      })
    },
    async makePlanRow(){

      this.data_plan = []
      let all_provider_id = []
      for(let i = 0; i < this.raw_data_of_plan.length; i++){
        let curr = this.raw_data_of_plan[i]
        // track_data
        var good_type_name = this.getGoodsTypeName(curr.goods_type_id)
        
        let vehicle_type_obj = curr.vehicle_type_id.split('|')
        let vehicle_type_id = vehicle_type_obj[0]
        var truck_type_name = this.dataTruckType[vehicle_type_id].name_model_th
        let vehicle_type_group_id = this.dataTruckType[vehicle_type_id].id_group//vehicle_type_obj[0]

        let location_display = this.findTwoPoint(curr.depart_address1_carrier, curr.arrive_address1_carrier)

        var split_other_service = curr.other_services.split('|')
        var other_service_display = ''
        for(var o_service_index = 0; o_service_index < split_other_service.length; o_service_index++ ){
          for(var k = 0; k < this.data_service_etc.length; k++){
            if(split_other_service[o_service_index] == this.data_service_etc[k].service_etc_id){
              if(other_service_display != ''){
                other_service_display += ','
              }
              other_service_display += this.data_service_etc[k].name_th
            }
          }
          
        }

        //t_c.id_carrier_tracking, t_c.depart_date, t_c.depart_address1, t_c.departure_lat_lon, t_c.arrival_lat_lon, t_c. arrive_address1
        let obj = {

          id_carrier_tracking: curr.id_carrier_tracking,

          created_carrier: curr.created_carrier,

          id_member_carrier: curr.id_member_carrier,
          id_member_goods_owner: curr.id_member_goods_owner,

          //ORDER
          good_type_name: good_type_name,
          quantity: curr.quantity,
          question: curr.question,
          total_distance: curr.total_distance,
          other_services: curr.other_services,
          other_service_display: other_service_display,

          //estimate
          price: curr.price,

          depart_date_carrier: curr.depart_date_carrier, //'2021-08-19 13:40:00'
          carrier_status_flg: curr.carrier_status_flg,

          departure_address: curr.depart_address1_carrier,
          departure_lat_lon_carrier: curr.departure_lat_lon_carrier,
          arrival_address: curr.arrive_address1_carrier,
          arrival_lat_lon_carrier: curr.arrival_lat_lon_carrier,
          location_display: location_display,

          vehicle_type_id: vehicle_type_id, 
          truck_type_name: truck_type_name,
          vehicle_type_group_id: vehicle_type_group_id,
          number_of_cars: curr.number_of_cars,
          mover_name: curr.mover_name, //'KDR TRANSPORT COMPANY CO.LTD',
          picture: null,
          member_name: '', 
          bookmark_flg:curr.bookmark_flg,
          rawData: curr,
          track_data: curr.track_data,
          star_flag : await this.findStarFlag(curr.id_member_carrier),
        }
        this.data_plan.push(obj)
        // console.log(this.data_plan)
        all_provider_id.push(curr.id_member_goods_owner)
      }

      let that = this
      setTimeout(function() {
        that.getAllProfilePicture(all_provider_id)
      }, 500)

      // this.data_truck = [
      //   {
      //     truck_license: '5กก 5455',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      //   {
      //     truck_license: '6กก 5055',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      //   {
      //     truck_license: '8กก 455',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      //   {
      //     truck_license: '8กก 5455',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      //   {
      //     truck_license: '9กก 5455',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      //   {
      //     truck_license: '5กก 5855',
      //     license_province: 'กรุงเทพมหานคร',
      //     vehicle_type_id: 1,
      //     truck_type_group: 4,
      //   },
      // ]

      var urlParams = new URLSearchParams(window.location.search)
      if(urlParams.get('data') != null && this.url_params_search_flag){
        this.url_params_search_flag = false
        const found = this.data_plan.find(element => element.id_carrier_tracking == urlParams.get('data'))
        this.showPlanActionClick(found)
      }

    },
    makeTrackRow(plan_data) {
      let data = plan_data
      this.arrlocation = []
      for (let index = 0; index <= 4; index++) {
        let data_push = {}
        const element = data.rawData
        // console.log(element)
        if(index == 0) {
          if(element.accept_flg != null && element.accept_flg == '1') {
            if(element.depart_address1_carrier != null && element.depart_date_carrier != null) {
              data_push = {
                name: element.depart_address1_carrier,
                date_appointment: element.accept_time,
                status: 'รอดำเนินการ',
              }
              this.arrlocation.push(data_push)
            } 
          }
        } 
        if(index == 1) {
          if(element.start_plan_flg != null && element.start_plan_flg == '1') {
            if(element.depart_address1_carrier != null && element.depart_date_carrier != null) {
              data_push = {
                name: element.depart_address1_carrier,
                date_appointment: element.start_plan_time,
                status: 'อยู่ระหว่างขนส่ง',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
        if(index == 2) {
          if(element.end_plan_flg != null && element.end_plan_flg == '1') {
            if(element.arrive_address1_carrier != null && element.end_plan_time != null) {
              data_push = {
                name: element.arrive_address1_carrier,
                date_appointment: element.end_plan_time,
                status: 'ดำเนินการเสร็จแล้ว',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
        if(index == 3) {
          if(element.carrier_status_flg != null && element.carrier_status_flg == '5') {
            if(element.arrive_address1_carrier != null && element.fin_plan_time != null) {
              data_push = {
                name: element.arrive_address1_carrier,
                date_appointment: element.fin_plan_time,
                status: 'ปิดงาน',
              }
              this.arrlocation.push(data_push)
            } 
          } 
        }
      }
      // console.log(this.arrlocation)
    },
    showPlanActionClick(data){
      this.click_select_items = null
      this.group_id = null
      this.group_id = data.vehicle_type_group_id
      this.select_items = data.truck_data
      this.select_items2 = data
      // console.log('รถ',data)

      if(data.track_data != undefined && data.track_data.length > 0) {
        this.click_select_items = data.track_data[0].blackbox_id
      }
      
      // console.log(data)
      // this.index_row_plan = index
      // this.p_of_plan_data = this.data_plan[this.index_row_plan]
      // this.p_of_plan_data = data
      let planUpdateData = data
      for (let index = 0; index < this.data_plan.length; index++) {
        const element = this.data_plan[index]
        if(planUpdateData.id_carrier_tracking == element.id_carrier_tracking) {
          element.star_flag = this.findStarFlag(element.id_member_goods_owner)
          this.p_of_plan_data = element
          this.fv_flag = element.bookmark_flg == '1' ? true : false
          this.star_flag = element.bookmark_flg == '1' ? true : false
        }
      }
      this.setMarkerToMap()
      this.makeTrackRow(this.p_of_plan_data)
      if(this.click_select_items != null){
        this.startRealtime(this.p_of_plan_data)
      }
      this.plan_transition = true
    },
    hidePlanActiveClick(){
      this.plan_transition = false
      this.clearMapFeature()
      // this.$router.push('/carrier/tracking')
      // this.makePlanRow()
    },
    showTruckTransition(){
      this.truck_transition = true 
    },
    hideTruckTransition(){
      this.truck_transition = false
    },
    findTwoPoint(p1,p2) { // ฟังก์ชั่นหาจังหวัดใน api ของ deemap
      let point1_split = []
      let point2_split = []
      let data_return = ''
      if(p1 != undefined && p1 != null) {
        let point1 = p1.trim()
        point1_split = point1.split(' ')
        if(point1_split.length > 0) {
          let ck_str = point1_split[point1_split.length - 1]
          let index = 1
          if(!isNaN(ck_str)) {
            index++
          }
          let prov = point1_split[point1_split.length - index]
          let prov_split = prov.split('.')
          if(prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      if(p2 != undefined || p2 != null) {
        let point2 = p2.trim()
        point2_split = point2.split(' ')
        if(point2_split.length > 0) {
          let ck_str = point2_split[point2_split.length - 1]
          if(data_return != '') {
            data_return += ' - '
          }
          let index = 1
          if(!isNaN(ck_str)) {
            index++
          }
          let prov = point2_split[point2_split.length - index]
          let prov_split = prov.split('.')
          if(prov_split.length > 1) {
            data_return += prov_split[1]
          } else {
            data_return += prov_split[0]
          }
        }
      }
      return data_return
    },
    close_popup() {
      var x = document.getElementById('marker')
      x.style.display = 'none'
    },
    arrBaseLayer(){
      // this.base_layer = [{"rr":"1"}]
      var arr = []
      var P_map = {}
      var dee_map_th2 = new layer.Tile({
        title: 'Dee-MapV2 (Thai)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?', 
          params: {
            'customer_key': '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_th2)
      // console.log(arr)
      P_map.dee_map_th2 = dee_map_th2

      var dee_map_en2 = new layer.Tile({
        title: 'Dee-MapV2 (En)',
        type: 'base',
        visible: false,
        maxZoom: 22,
        source: new source.TileWMS({
          url: 'https://www.deemap.com/DeeMap/gwc/service/wms?',
          params: {
            customer_key: '9P8qe4ndbB8ep6hJ',
            'LAYERS': 'DeeMap2_THA_Map_EN',
            'FORMAT': 'image/png8',
            'VERSION': '1.1.1',
            'TILED': true,
          },
        }),
      })
      arr.unshift(dee_map_en2)
      P_map.dee_map_en2 = dee_map_en2

      var layerNokiaHere = new layer.Tile({
        title: 'HereMap',
        type: 'base',
        visible: false,
        maxZoom: 18,
        source: new source.XYZ({
          url: 'http://1.base.maps.cit.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG&xnlp=CL_JSMv2.5.3,SID_A78335BD-4DDE-4BBB-B544-B856888E9E89',
        })
      })
      arr.unshift(layerNokiaHere)
      P_map.layerNokiaHere = layerNokiaHere

      var satellite = new layer.Tile({
        title: 'Satellite',
        type: 'base',
        visible: false,
        maxZoom: 20,
        source: new source.XYZ({
          url: 'http://1.aerial.maps.cit.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?app_id=o9W16gheao25dXp9a9Kg&app_code=Smo6mT91hk9YhqJSclXrSA&lg=THA&lg2=ENG',
        })
      })
      arr.unshift(satellite)
      P_map.satellite = satellite

      var openstreetmap = new layer.Tile({
        // A layer must have a title to appear in the layerswitcher
        title: 'OSM',
        // Again set this layer as a base layer
        type: 'base',
        visible: true,
        source: new source.OSM(),
      })
      arr.unshift(openstreetmap)
      P_map.openstreetmap = openstreetmap

      this.base_layer = {arr: arr, POINT: P_map}
    },
    initialMap() {
      var map = new ol.Map({
        target: this.$refs.map,
        // layers: [
        //   new layer.Tile({
        //     source: new source.OSM(),
        //   }),
        // ],
        view: new ol.View({
          center: proj.fromLonLat([100.60379663561206, 13.676865941765966]),
          zoom: 10,
        }),
      })
      

      
      
      //put point on layer
      

      // map.on('click', function(evt) {
      //   const feature = map.forEachFeatureAtPixel(evt.pixel, function(feature) {
      //     return feature
      //   })
      //   if (feature) {
      //     let data = feature.get('data')
      //     // console.log(data)
      //     // 13.6768|100.603694
      //     const pos = proj.fromLonLat([100.603694, 13.6768])
      //     var x = document.getElementById("marker");
      //     x.style.display = "block";
      //     // console.log(x)
      //     const marker = new Overlay({
      //       position: pos,
      //       positioning: "center-center",
      //       element: document.getElementById("marker"),
      //       stopEvent: false,
      //     })
      //     map.addOverlay(marker);
      //   }
      // })


      

      var arr_vector_layer = []
      this.directionSource = new source.Vector({
        features: [],
      }),
      this.directionLayer = new layer.Vector({
        title: 'route',
        // name: 'route',
        source: this.directionSource,
      })
      
      arr_vector_layer.push(this.directionLayer)
      // set name of vector for easy finding after
      //vectorLayer.set('name', 'route')
      // map.addLayer(this.directionLayer)

      let group_overlay_feature = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Feature',
        fold: 'close',
        layers: arr_vector_layer,
      })
      
      this.map = map
      var arr_layer = this.base_layer.arr
      this.base_layer.POINT['openstreetmap'].setVisible(true)
      let group_base = new layer.Group({
        // A layer must have a title to appear in the layerswitcher
        'title': 'Base Layer',
        fold: 'open',
        layers: arr_layer,
      })


      var layerSwitcher = new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      })
      

      this.pinPointSource = new source.Vector({
        features: [],
      })
      this.marker_s = new source.Vector({
        features: [],
        
      })
      this.pinPointLayer = new layer.Vector({
        name: 'pin_point',
        source: this.pinPointSource,
      })
      this.mark_s = new layer.Vector({
        name: 'mark',
        source: this.marker_s,
      })
      this.mark_s.setZIndex(999)
      this.pinPointLayer.setZIndex(990)
      this.directionLayer.setZIndex(980)
      this.map.addLayer(group_overlay_feature)
      this.map.addLayer(group_base)
      map.addLayer(this.pinPointLayer)
      map.addLayer(this.mark_s)
      map.addControl(layerSwitcher)
      //this.setActionOnMap()
    },
    clearMapFeature(){
      this.pinPointSource.clear()
      this.directionSource.clear()
    },
    async setMarkerToMap(){

      // this.pinPointSource.clear()
      // this.directionSource.clear()
      await this.clearMapFeature()
      
      let departure_lat_lon = this.p_of_plan_data.departure_lat_lon_carrier //"13.676642422364836|100.60368418693544"
      let arrival_lat_lon = this.p_of_plan_data.arrival_lat_lon_carrier //"17.164574201478075|104.15350198745728"
      var departure = departure_lat_lon.split('|')
      var ariival = arrival_lat_lon.split('|')

      let coordinate2 = [parseFloat(departure[1]), parseFloat(departure[0])]
      let coordinate = [parseFloat(ariival[1]), parseFloat(ariival[0])]

      let check1 = coordinate[0] != null && coordinate[0] != undefined && coordinate[0] != ''
      let check2 = coordinate2[1] != null && coordinate2[1] != undefined && coordinate2[1] != ''
      if (check1 && check2) {
        await this.setPinPointLocation(coordinate, 0)
        await this.setPinPointLocation(coordinate2, 1)
        await this.getDirectionsService_HereMap(coordinate, coordinate2)
      }

    },
    setPinPointLocation(coordinate, index) { //index

      var new_point = new ol.Feature({
        geometry: new geom.Point(proj.fromLonLat([coordinate[0], coordinate[1]])),
      })
  
      var pinpoint
      if (index == 0) {
        pinpoint = require('@/assets/images/ol/location_no_1.png') //this.src
      } else {
        pinpoint = require('@/assets/images/ol/location_no_2.png') //this.src2
      }
      //set style of point
      new_point.setStyle(
        new style.Style({
          image: new style.Icon({
            crossOrigin: 'anonymous',
            scale: 1,
            anchor: [0.5, 1],
            anchorOrigin: 'top-left',
            src: pinpoint,
          }),
        }),
      )
      this.pinPointSource.addFeature(new_point)
      
    },
    getDirectionsService_HereMap_old(lo_1, lo_2) {
      var that = this
      var app_id = 'o9W16gheao25dXp9a9Kg' //'V78Ttyq1RSEy7KsExlfq'//'o9W16gheao25dXp9a9Kg'
      var app_code = 'Smo6mT91hk9YhqJSclXrSA' //'nqGgcND3Yoof9VDmsYc7Hg'//'Smo6mT91hk9YhqJSclXrSA'

      //    var app_id = 'rBwB1Fllq3IHyosgCFs5'
      //    var app_code = '-a-M-daFoGNJuMa7DjF4RA'

      // eslint-disable-next-line no-undef
      var platform = new H.service.Platform({
        app_id: app_id, //Key fluke
        app_code: app_code, //Key fluke
        'apikey': 'Due7AzLBaEnUBQSBPunaYDOLfk4fnPCQe1YuN6ys',
      })

      var origin = lo_1[1] + ',' + lo_1[0]
      var destination = lo_2[1] + ',' + lo_2[0]

      // eslint-disable-next-line no-unused-vars
      var routingParameters = {
        mode: 'fastest;truck',
        waypoint0: 'geo!' + origin, //'geo!50.1120423728813,8.68340740740811',
        waypoint1: 'geo!' + destination, //'geo!52.5309916298853,13.3846220493377',
        representation: 'display',
        routeattributes: 'labels',
        alternatives: 2,
      }
      var router = platform.getRoutingService()
      router.calculateRoute(
        routingParameters,
        function (result) {
          if (result.response.route) {
            // console.log('here map')
            // console.log(result.response)
            that.directions_data = result.response
            that.calDirectionsHereMap()
          }
        },
        function (error) {
          console.log(error.message)
        },
      )
    },
    getDirectionsService_HereMap(lo_1, lo_2) {
      let that = this
      rhm.getDirectionsService({LAT : lo_1[1], LON : lo_1[0]}, {LAT : lo_2[1], LON : lo_2[0]}, e => {
        // console.log(e)
        that.directions_data = e
        that.calDirectionsHereMap()
      })
    },
    calDirectionsHereMap() {
      // remove layer if have layer already
      //if (this.findLayer('route')) {
      //  this.directionSource.clear()
      //}
      var locations = []
      var latlonData = this.directions_data.route[0].shape

      for (let index = 0; index < latlonData.length; index++) {
        let lat_lon = latlonData[index].split(',')
        locations[index] = [parseFloat(lat_lon[1]), parseFloat(lat_lon[0])]
      }
      // console.log(locations)
      // for set new center
      // var half = (latlonData.length / 2).toFixed(0)
      // var halfRouteData = locations[half]

      // var latlan_c = proj.fromLonLat([parseFloat(halfRouteData[0]), parseFloat(halfRouteData[1])])
      // var zoom_c = 12
      // var distance = parseInt(this.total_distance)
      // if (distance < 40) {
      //   zoom_c = 13
      // } else if (distance < 70) {
      //   zoom_c = 12
      // } else if (distance < 100) {
      //   zoom_c = 11
      // } else if (distance < 150) {
      //   zoom_c = 10
      // } else if (distance < 300) {
      //   zoom_c = 9
      // } else if (distance < 500) {
      //   zoom_c = 8
      // } else if (distance < 750) {
      //   zoom_c = 7
      // } else {
      //   zoom_c = 6
      // }
      // console.log(zoom_c)
      // this.map.getView().animate({
      //   center: latlan_c,
      //   zoom: 12,
      //   duration: 800,
      // })

      var route = new geom.LineString(locations).transform('EPSG:4326', 'EPSG:3857')

      var new_point = new ol.Feature({
        geometry: route,
      })

      //set style of route line
      new_point.setStyle(
        new style.Style({
          stroke: new style.Stroke({
            width: 6,
            color: [0, 152, 209, 0.8],
          }),
        }),
      )
      var that = this
      setTimeout(function() {
        // set vector
        that.directionSource.addFeature(new_point)
        that.moveLayerBefore(that.findLayer('pin_point'), that.findLayer('route'), that.map.getLayers())
      }, 1000)
      
    },
    moveLayerBefore(old_idx, new_idx, layer) { // ฟังก์ชั่นเรียก layer ของ openlayer จากชื่อ layer
      if (old_idx === -1 || new_idx === -1) {
        return false
      }
      var that = this
      // layer.removeAt(old_idx)
      layer = that.map.getLayers().removeAt(old_idx)
      // layer = this.map
      this.map.getLayers().insertAt(new_idx, layer)
    },
    findLayer(layer_name) {
      var layer_idx = -1
      var layerArr = this.map.getLayers().getArray()
      for (let index = 0; index < layerArr.length; index++) {
        const element = layerArr[index]
        var this_layer_name = element.get('name')
        if (this_layer_name == layer_name) {
          layer_idx = index
        }
      }
      return layer_idx
    },
    open_modal_detail(data){
      // data
      var param_detail = {}
      param_detail.id_order_tracking = data.id_carrier_tracking       //data.id_order_tracking
      param_detail.created = data.created_carrier                  //data.created
      param_detail.order_period = '2021-08-26 00:00:00'            //data.order_period
      param_detail.period_display =  '2'         //data.order_period_display
      param_detail.good_type_name = data.good_type_name    //data.good_type_name
      param_detail.quantity = data.quantity               //data.quantity
      param_detail.question = data.question                //data.question
      param_detail.arrival_address = data.arrival_address          //data.arrival_address
      param_detail.departure_address = data.departure_address       //data.departure_address
      param_detail.total_distance = data.total_distance          //data.total_distance
      param_detail.pickup_time_1 = new Date()          //data.total_distance
      param_detail.pickup_time_2 = new Date()           //data.total_distance
      //vehicle_type_id
      param_detail.vehicle_type_group_id = data.vehicle_type_group_id  //data.vehicle_type_group_id 
      param_detail.truck_type_name = data.truck_type_name        //data.truck_type_name
      param_detail.other_service_display = data.other_service_display   //data.other_service_display
      param_detail.expected_price = data.price          //data.expected_price
      
      param_detail.arrival_lat_lon =  data.arrival_lat_lon_carrier        //data.arrival_lat_lon
      param_detail.departure_lat_lon = data.departure_lat_lon_carrier      //data.departure_lat_lon
      // console.log('param_detail',param_detail)
      this.$refs.plandetail.bt_detail(param_detail)
    },
    bt_detail(data){
      var param_send = {provider_id:data.id_member_goods_owner } //id_member_carrier
      this.$refs.modalCompany.setProviderData(param_send)
      this.$bvModal.show('modal_company_detail')

    },
    getDataGoodsType() {
      // get ข้อมูล ประเภทสินค้า
      service.postService('getGoodsType').then(rp => {
        // console.log(rp)
        let data = rp.data
        for (let index = 0; index < data.length; index++) {
          var goods_type_sel = {
            goods_type_id: data[index]['goods_type_id'],
            goods_type_name_th: data[index]['goods_type_name_th'],
            goods_type_name_en: data[index]['goods_type_name_en'],
            goods_type_status: '',
          }
          //this.dataGoodsType_master.push(goods_type_sel) // for insert data to db
          this.dataGoodsType_data.push(goods_type_sel)
          //if (this.account_lang != 'th') {
          //  this.goods_lang = false
          //}
        }
        // console.log(this.dataGoodsType_data2)
      })
    },
    getGoodsTypeName(goodsTypeId) { // นำ goods_type_id มาหาชื่อของประเภทสินค้า
      var data_return = ''
      if(goodsTypeId != null && goodsTypeId != '') {
        var res = goodsTypeId.split('|')
        var count_goods_type = 0
        // console.log(res)
        // console.log(this.dataGoodsType_data)
        for (let index2 = 0; index2 < res.length; index2++) {
          var goodsType_id = res[index2]
          if(count_goods_type < 2) {
            for (let index = 0; index < this.dataGoodsType_data.length; index++) {
              const element = this.dataGoodsType_data[index]
              if (element.goods_type_id == goodsType_id) {
                if (data_return != '') {
                  data_return += ', '
                  data_return += element.goods_type_name_th
                  if(!this.check_lang){
                    data_return += element.goods_type_name_en
                  }
                } else {
                  data_return = element.goods_type_name_th
                  if(!this.check_lang){
                    data_return += element.goods_type_name_en
                  }
                }
              }
            }
            count_goods_type++
          } else {
            data_return += ', ...'
          }
        }
      } else {
        data_return = '-'
      }
      // console.log(data_return)
      return data_return
    },
    askStartPlan() {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“เริ่มแผนงานรถว่าง”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendStartPlan(this.p_of_plan_data)
        } else {
          return false
        }
      })
    },
    openProofModal(param_send) {
      this.$refs.modalProof.setProviderData(param_send)
    },
    askFinPlan() {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + '“จบงานรถว่าง”' + '<br />' + 'ใช่หรือไม่',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          // this.sendFinPlan(this.p_of_plan_data)
          this.openProofModal(this.p_of_plan_data)
        } else {
          return false
        }
      })
    },
    sendStartPlan(option) {
      var data = option
      var that = this
      service
        .postService('sendStatusPlan', {
          plan_data: data,
          mode: 'start_plan',
          mode2: 'tracking',
          account_data: this.account_data,
        })
        // eslint-disable-next-line no-unused-vars
        .then(rp => {
          // console.log(rp)
          if(rp.code == '200') {
            global_func.alertMessage('เริ่มแผนงานรถว่างเรียบร้อย', '', 'success')
            that.p_of_plan_data.carrier_status_flg = 3
            // that.makeTrackRow(that.p_of_plan_data)
            setTimeout(function() {
              that.getTracking()
            }, 1000)
            setTimeout(function() {
              that.showPlanActionClick(data)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    sendFinPlan(option) {
      var data = this.p_of_plan_data
      var that = this
      console.log(option)
      service
        .postService('sendStatusPlan', {
          plan_data: data,
          mode: 'fin_plan',
          mode2: 'tracking',
          account_data: this.account_data,
        })
        // eslint-disable-next-line no-unused-vars
        .then(rp => {
          // console.log(rp)
          if(rp.code == "200") {
            global_func.alertMessage('จบงานรถว่างเรียบร้อย', '', 'success')
            that.p_of_plan_data.carrier_status_flg = 4
            // that.makeTrackRow(that.p_of_plan_data)
            setTimeout(function() {
              that.getTracking()
            }, 1000)
            setTimeout(function() {
              that.showPlanActionClick(data)
            }, 1500)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }

        })
    },
    askCancelPlan(option) {
      Swal.fire({
        title: 'คุณยืนยันที่จะ' + '<br />' + 'ยกเลิกงานนี้' + '<br />' + 'ใช่หรือไม่' + '<br /><br />' + '*** การยกเลิกงานอาจจะมีค่าปรับเกิดขึ้น<br />ขึ้นอยู่กับผู้ให้บริการขนส่ง',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        allowOutsideClick: true,
        confirmButtonColor: '#34c38f',
        // cancelButtonColor: '#FFFFFF',
        // cancelButtonTextColor: '#343C48',
        confirmButtonText: 'ใช่ ฉันยืนยัน',
        cancelButtonText: 'ยกเลิก',
      }).then(result => {
        if (result.value) {
          this.sendCancelPlan(option)
        } else {
          return false
        }
      })
    },
    sendCancelPlan(estimate) {
      // console.log(estimate)
      var that = this
      service
        .postService('sendCancelPlanCarrier', {
          estimate_data: estimate,
          account_data: this.account_data,
        })
        .then(async (rp) => {
          // console.log(rp)
          if(rp.code == "200") {
            global_func.alertMessage(that.lang.alert.cancel, '', 'success')
            // that.changeDisplayAfterCancelPlan()
            await this.delete_truck(estimate.rawData.id_carrier)
          } else {
            Swal.fire({
              icon: 'error',
              title: that.lang.alert.failed,
              confirmButtonText: that.lang.btn.close,
            })
          }
        })
    },
    search_plan() {
      this.getTracking()
    },
    timeout_search_plan() {
      var that = this
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.getTracking()
      }, 500)
    },
    getSubscribeData() {
      service
        .postService('getSubscribeData', {
          account_data : this.account_data,
        })
        .then(rp => {
          // console.log(rp)
          if(rp.status == 'ok') {
            this.subscribe_data = []
            this.subscribe_data = rp.data
          }
          // console.log(this.subscribe_data)
        })
    },
    findStarFlag(id_member) {
      let subscribe_data = this.subscribe_data
      let flag = '0'
      for (let index = 0; index < subscribe_data.length; index++) {
        const element = subscribe_data[index]
        if(element.provider_id == id_member) {
          flag = '1'
        }
      }
      return flag
    },
    getAllProfilePicture(all_provider_id) {
      let provider_id = [...new Set(all_provider_id)]
      // console.log(provider_id)
      service
        .postService('getAllProfilePicture', {
          id_member_data: provider_id,
        })
        .then(rp => {
          // console.log(rp)
          this.setProfilePicture(rp.data)
        })
    },
    setProfilePicture(profile_picture_data) {
      let temp_data_plan = this.data_plan
      this.data_plan = []
      for (let index = 0; index < temp_data_plan.length; index++) {
        let element = temp_data_plan[index]
        // console.log(element)
        const blob = profile_picture_data.filter((e) => {
          return e.id_member == element.id_member_goods_owner
        })
        element.pic_profile = blob[0].pic_profile
        this.data_plan.push(element)
      }
    },
    async delete_truck(id_carrier){
      let that = this
      if(id_carrier != undefined && id_carrier != '') {
        service
          .postService('deleteMatchCar', {
            id_carrier: id_carrier,
          })
          .then(rp => {
            let result = rp
            // console.log(result.data)
            if(result.code == 200){
              this.hidePlanActiveClick()
              this.getTracking()
            }else{
              Swal.fire({
                icon: 'error',
                title: that.lang.alert.failed,
                confirmButtonText: that.lang.btn.close,
              })
            }
          })
      } else {
        Swal.fire({
          icon: 'error',
          title: that.lang.alert.failed,
          confirmButtonText: that.lang.btn.close,
        })
      }
    },
  },
}
</script>

<template>
  <Layout>
    <b-row>
      <!-- TAB LEFT CARD -->
      <b-col lg="4">
        <div class="card-body" style="height:100vh;">
          <div>
            <!-- <nav>
              <b-tabs v-model="active_tab" justified nav-class="nav-tabs-custom">
                <b-tab :active="active_tab == 0"> 
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block topnav-text">แบบแผนงาน</span>
                  </template>
                </b-tab>
                <b-tab :active="active_tab == 1" disabled>
                  <template v-slot:title>
                    <span class="d-none d-sm-inline-block topnav-text">แบบรายคัน (soon)</span>
                  </template>
                </b-tab>
              </b-tabs>
            </nav> -->
            <template v-if="active_tab == 0">
              <div style="padding: 10px 0px 10px 0px; background: #FFFFFF 0% 0% no-repeat padding-box;">
                <b-row>
                  <b-col lg="6">
                    <select v-model="sort_by_plans_status" class="form-control" @change="search_plan()">
                      <option value="0" selected>สถานะทั้งหมด</option>
                      <option value="1">รอเสนองาน</option>
                      <option value="2">รอดำเนินการ</option>
                      <option value="3">อยู่ระหว่างขนส่ง</option>
                      <option value="4">ดำเนินการเสร็จแล้ว</option>
                      <option value="5">ปิดงาน</option>
                    </select>
                  </b-col>
                  <b-col lg="6">
                    <div class="input-group prepend">
                      <b-form-input id="search_plans_text" v-model="sort_by_plans_code" type="text" class="form-control custom-input-prepend" :placeholder="lang.placeholder.search_plans_text" @keyup="timeout_search_plan()" />
                      <div class="input-group-prepend custom-input-group-prepend" @click="search_plan('plan_code')">
                        <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <simplebar class="simplebar-plan-display" style="height:calc(100vh - 200px); background: #FFFFFF 0% 0% no-repeat padding-box;padding:0;">
                <div role="tablist">
                  <b-card v-for="(option,index) in data_plan" :key="index" no-body class="mb-1 shadow-none tracking-plan-row" style="position: relative;">
                    <div @click="showPlanActionClick(option)">
                      <b-row class="col-margin-bot-1;">
                        <b-col>
                          <div style="display:flex;">
                            <div style="width:100%;">
                              <span style="font-size: 20px;font-weight: 500;"> {{ option.id_carrier_tracking }} </span>                   
                              <span v-if="option.carrier_status_flg == 0" class="font-color-blue"> (งานใหม่) </span>
                              <span v-if="option.carrier_status_flg == 1" class="font-color-blue"> (รอเสนองาน) </span>
                              <span v-if="option.carrier_status_flg == 2" class="font-color-blue"> (รอดำเนินการ) </span>
                              <span v-if="option.carrier_status_flg == 3" class="font-color-blue"> (อยู่ระหว่างขนส่ง) </span>
                              <span v-if="option.carrier_status_flg == 4" class="font-color-blue"> (ดำเนินการเสร็จแล้ว) </span>
                              <span v-if="option.carrier_status_flg == 5" class="font-color-blue"> (ปิดงาน) </span>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1;">
                        <b-col style="margin-bottom:5px;">
                          <small class="text-muted" style="font-size:73%;"> เริ่มงาน {{ option.depart_date_carrier | moment("dd DD/MM/YY HH:mm") }} น. </small>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1;">
                        <b-col style="margin-bottom:5px;">
                          จุดรับ - ส่งสินค้า:
                          <span class="font-color-blue" style="float:right;"> {{ option.location_display }}</span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1;">
                        <b-col style="margin-bottom:5px;">
                          ประเถทรถ:
                          <span class="font-color-blue" style="float:right;">
                            <img v-if="option.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                            <img v-else-if="option.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                            <img v-else-if="option.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                            <img v-else-if="option.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                            <img v-else-if="option.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                            <img v-else-if="option.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                            {{ option.truck_type_name }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="col-margin-bot-1;">
                        <b-col style="margin-bottom:5px;">
                          จำนวนรถ:
                          <span class="font-color-blue" style="float:right;">{{ option.number_of_cars }} คัน</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row class="col-margin-bot-1;">
                        <b-col>
                          <img v-if="option.pic_profile" :src="option.pic_profile" height="30px" />
                          <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="30px" />
                          <span style="margin-left: 10px;">
                            {{ option.mover_name }}
                          </span>
                          <!-- <img src="@/assets/images/DTC.gif" height="30px" style="margin-right: 10px" /> -->
                        </b-col>
                      </b-row>
                    </div>
                    <div style="text-align:right;position: absolute;right:20px;">
                      <i :class="option.bookmark_flg == 1 ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="option.bookmark_flg == 1 ? 'color:#FAAB00;font-size:20px;' : 'font-size:20px;'" @click="update_favorite(option)" />
                    </div>
                  </b-card>
                </div>
              </simplebar>
            </template>
                    
            <template v-else>
              <div style="padding: 20px 20px 0px 20px; background: #F7F7F7 0% 0% no-repeat padding-box;">
                <b-row>
                  <b-col lg="4">
                    <select class="form-control">
                      <option selected hidden>รถทั้งหมด</option>
                      <option>รอการตอบรับ</option>
                      <option>มีผู้สนใจ</option>
                      <option>รอเริ่มงาน</option>
                      <option>กำลังดำเนินการ</option>
                      <option>สำเร็จ</option>
                      <option>จบแผนงาน</option>
                      <option>ยกเลิก</option>
                    </select>
                  </b-col>
                  <b-col lg="8" class="col-margin-bot-1">
                    <div class="input-group prepend">
                      <b-form-input id="search_plans_text" v-model="search_tracking" type="text" class="form-control custom-input-prepend" :placeholder="'ค้นหา ทะเบียนรถ'" />
                      <div class="input-group-prepend custom-input-group-prepend" @click="search_plan('plan_code')">
                        <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <simplebar class="simple-truck-display" style="max-height: 66.5vh; min-height: 66.5vh; background: #F7F7F7 0% 0% no-repeat padding-box;">
                <div role="tablist">
                  <b-card v-for="(option,index) in data_truck" :key="index" no-body class="mb-1 shadow-none tracking-plan-row" @click="showTruckTransition()">
                    <b-row class="col-margin-bot-1;">
                      <b-col>
                        <span style="font-weight: bold;"> {{ option.truck_license }} {{ option.license_province }}</span>
                      </b-col>
                    </b-row>

                    <b-row class="col-margin-bot-1;">
                      <b-col>
                        <img v-if="option.truck_type_group == '1'" src="@/assets/images/truck_img/4_wheels.png" width="60px" style="margin-right: 20px" />
                        <img v-else-if="option.truck_type_group == '2'" src="@/assets/images/truck_img/6_wheels.png" width="60px" style="margin-right: 20px" />
                        <img v-else-if="option.truck_type_group == '3'" src="@/assets/images/truck_img/10_wheels.png" width="60px" style="margin-right: 20px" />
                        <img v-else-if="option.truck_type_group == '4'" src="@/assets/images/truck_img/trailer2.png" width="60px" style="margin-right: 20px" />
                        <img v-else-if="option.truck_type_group == '5'" src="@/assets/images/truck_img/trailer.png" width="60px" style="margin-right: 20px" />
                        <img v-else-if="option.truck_type_group == '6'" src="@/assets/images/truck_img/tow_truck.png" width="60px" style="margin-right: 20px" />
                        <span class="font-color-blue">4 ล้อ - ตู่ทึบ</span>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </simplebar>
            </template>
          </div>
        </div>
      </b-col>

      <!-- TAB RIGHT MAP -->
      <b-col lg="8" style="padding: 0px;">
        <!-- แผนที่ -->
        <div id="map" ref="map" />
      </b-col>

      <!-- START TRANSITION PLAN -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="plan_transition" class="col-main-menu h-100vh location_menu" :class="{ active: plan_transition }">
          <!-- open_location_sel_status -->
          <div class="card">
            <simplebar class="card-body" style="height:calc(100vh - 115px)">
              <div style="padding-left: 2%; padding-right:2%;">
                <!--padding-left: 4%; padding-right:4%;  -->
                <b-row class="col-margin-bot-2" style="padding-top:5px;padding-bottom:10px;margin:0;">
                  <span class="d-flex justify-content-center font-size-16 btn_back_blue" @click="clearData(),hidePlanActiveClick()"><i class="ri-arrow-left-line" style="margin-right:5px;" />ย้อนกลับ</span>
                </b-row>
                <b-row class="col-margin-bot-1" style="">
                  <b-col style="display:flex;"> 
                    <div style="width:100%;">
                      <span style="font-size: 20px;font-weight: 500;"> {{ p_of_plan_data.id_carrier_tracking }} </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 0" class="font-color-blue"> (งานใหม่) </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 1" class="font-color-blue"> (รอเสนองาน) </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 2" class="font-color-blue"> (รอดำเนินการ) </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 3" class="font-color-blue"> (อยู่ระหว่างขนส่ง) </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 4" class="font-color-blue"> (ดำเนินการเสร็จแล้ว) </span>
                      <span v-if="p_of_plan_data.carrier_status_flg == 5" class="font-color-blue"> (ปิดงาน) </span>
                    </div>
                    <div style="width:100%;text-align:right;">
                      <i :class="fv_flag == true ? 'ri-bookmark-fill': 'ri-bookmark-line'" :style="fv_flag == true ? 'color:#FAAB00;cursor:pointer;font-size:20px;' : 'cursor:pointer;font-size:20px;'" @click="update_favorite(p_of_plan_data,'in')" />
                    </div>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    <small class="text-muted" style="font-size:73%;"> เริ่มงาน {{ p_of_plan_data.depart_date_carrier | moment("dd DD/MM/YY HH:mm") }} น. </small>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    จุดรับส่ง - ส่งสินค้า: <span class="font-color-blue" style="float:right;"> {{ p_of_plan_data.location_display }}</span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    ประเถทรถ:
                    <span class="font-color-blue" style="float:right;">
                      <img v-if="p_of_plan_data.vehicle_type_group_id == '1'" src="@/assets/images/truck_img/4_wheels.png" width="40px" style="margin-right: 20px" />
                      <img v-else-if="p_of_plan_data.vehicle_type_group_id == '2'" src="@/assets/images/truck_img/6_wheels.png" width="40px" style="margin-right: 20px" />
                      <img v-else-if="p_of_plan_data.vehicle_type_group_id == '3'" src="@/assets/images/truck_img/10_wheels.png" width="40px" style="margin-right: 20px" />
                      <img v-else-if="p_of_plan_data.vehicle_type_group_id == '4'" src="@/assets/images/truck_img/trailer2.png" width="40px" style="margin-right: 20px" />
                      <img v-else-if="p_of_plan_data.vehicle_type_group_id == '5'" src="@/assets/images/truck_img/trailer.png" width="40px" style="margin-right: 20px" />
                      <img v-else-if="p_of_plan_data.vehicle_type_group_id == '6'" src="@/assets/images/truck_img/tow_truck.png" width="40px" style="margin-right: 20px" />
                      {{ p_of_plan_data.truck_type_name }}
                    </span>
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    จำนวนรถ: <span class="font-color-blue" style="float:right;">{{ p_of_plan_data.number_of_cars }} คัน</span>
                  </b-col>
                </b-row>
                <!-- <b-row class="col-margin-bot-1">
                  <b-col> 
                    ข้อมูลแผนงาน: <span style="float:right;" @click="open_modal_detail(p_of_plan_data)"><u>รายละเอียด</u></span>
                  </b-col>
                </b-row> -->
                <b-row class="col-margin-bot-1">
                  <b-col class="d-flex justify-content-start">
                    <span class="">
                      ข้อมูลแผนงาน :
                    </span>
                  </b-col>
                  <b-col class="d-flex justify-content-end">
                    <span class=" font-link" @click="open_modal_detail(p_of_plan_data)">
                      รายละเอียด
                    </span>
                  </b-col>
                </b-row>

                <hr />

                <b-row>
                  <b-col lg="9" class="d-flex align-items-center">
                    <div class="d-flex align-items-center" style="margin-right: 10px">
                      <div style="display:flex;">
                        <div style="margin-right:10px;">
                          <i :class="p_of_plan_data.star_flag == '1' ? 'ri-star-fill': 'ri-star-line'" :style="p_of_plan_data.star_flag == '1' ? 'color:#FAAB00;cursor:pointer;font-size:20px;' : 'cursor:pointer;font-size:20px;'" @click="updateStar(p_of_plan_data)" />
                        </div>
                        <div>
                          <img v-if="p_of_plan_data.pic_profile" :src="p_of_plan_data.pic_profile" height="30px" />
                          <img v-else src="@/assets/images/plans_display/Group 3361@2x.png" height="30px" />
                        </div>
                      </div>
                    </div>
                    <span class="">
                      {{ p_of_plan_data.mover_name }}
                    </span>
                  </b-col>
                  <b-col lg="3" class="d-flex align-items-center justify-content-end">
                    <span class=" font-link" @click="bt_detail(p_of_plan_data)">
                      ข้อมูลบริษัท
                    </span>
                  </b-col>
                  <!-- <b-col>
                    <div style="display:flex;">
                      <div style="margin-right:10px;">
                        <i :class="star_flag == true ? 'ri-star-fill': 'ri-star-line'" :style="star_flag == true ? 'color:#FAAB00;cursor:pointer;font-size:20px;' : 'cursor:pointer;font-size:20px;'" @click="updateStar(p_of_plan_data)" />
                      </div>
                      <div>
                        <img src="@/assets/images/DTC.gif" height="30px" style="margin-right: 10px" /> {{ p_of_plan_data.mover_name }} <span style="float:right;" @click="bt_detail(p_of_plan_data)"><u>ข้อมูลบริษัท</u></span> 
                      </div>
                    </div>
                  </b-col> -->
                </b-row>
                            
                <hr />
                      
                <select v-if="click_select_items != null" v-model="click_select_items" style="margin-bottom:20px;" class="form-control" @change="startRealtime(p_of_plan_data)">
                  <option v-for="option in select_items" :key="option.blackbox_id" :value="option.blackbox_id">
                    {{ option.truck_name }} 
                  </option>
                </select>

                <b-row v-for="(option3,index3) in arrlocation" :key="index3">
                  <b-col lg="2">
                    <b-row>
                      <b-col style="text-align: center; font-size:0.8rem;">
                        <span>
                          {{ option3.date_appointment | moment("DD/MM/YYYY") }} 
                        </span>
                      </b-col>
                      <b-col style="text-align: center; font-size:0.8rem;">
                        <span>
                          {{ option3.date_appointment | moment("HH:mm") }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="10" class="d-flex align-items-start">
                    <ul class="list-unstyled activity-wid" style="margin-top: 0px">
                      <li class="activity-list">
                        <div class="activity-icon avatar-xs">
                          <span class="avatar-title bg-soft-primary text-primary rounded-circle" style="align-items: flex-start">
                            <i :class="`ri-map-pin-fill`" />
                          </span>
                        </div>
                        <template v-if="index3 != 0 && index3 != 3">
                          <span>
                            {{ option3.name }}
                          </span>
                          <br />
                        </template>
                        <span>
                          ({{ option3.status }})
                        </span>
                        <br />
                        <br />
                      </li>
                    </ul>
                  </b-col>
                  <br />
                </b-row>
                <br />
                <b-row v-if="p_of_plan_data.rawData.carrier_status_flg != '5'">
                  <b-col v-if="click_select_items == null">
                    <div class="d-flex justify-content-center big_btn_green_dis">
                      <span class="margin-top-bot-auto">เริ่มแผนงาน</span>
                    </div>
                  </b-col>
                  <b-col v-else-if="p_of_plan_data.rawData.carrier_status_flg == '2'" @click="askStartPlan(p_of_plan_data)">
                    <div class="d-flex justify-content-center big_btn_green">
                      <span class="margin-top-bot-auto">เริ่มแผนงาน</span>
                    </div>
                  </b-col>
                  <b-col v-else-if="p_of_plan_data.rawData.carrier_status_flg == '3'" @click="askFinPlan(p_of_plan_data)">
                    <div class="d-flex justify-content-center big_btn_green">
                      <!-- <span class="margin-top-bot-auto">สิ้นสุดแผนงาน</span> -->
                      <span class="margin-top-bot-auto">จบงานรถว่าง</span>
                    </div>
                  </b-col>
                  <b-col v-else-if="p_of_plan_data.rawData.carrier_status_flg == '4'">
                    <div class="d-flex justify-content-center big_btn_green_dis">
                      <span class="margin-top-bot-auto">รอเจ้าของสินค้ายืนยัน</span>
                    </div>
                  </b-col>
                  <b-col v-if="p_of_plan_data.rawData.carrier_status_flg == '2'">
                    <div class="d-flex justify-content-center big_btn_red" @click="askCancelPlan(p_of_plan_data)">
                      <span class="margin-top-bot-auto">ยกเลิกงาน</span>
                    </div> 
                  </b-col>
                  <b-col v-else>
                    <div class="d-flex justify-content-center big_btn_red_dis">
                      <span class="margin-top-bot-auto">ยกเลิกงาน</span>
                    </div> 
                  </b-col>
                </b-row>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- END TRANSITION PLAN-->

      <!-- START TRANSITION TRUCK -->
      <transition name="bounce" enter-active-class="fadeInLeft" leave-active-class="fadeOutLeft">
        <div v-if="truck_transition" class="col-main-menu h-100vh location_menu" :class="{ active: truck_transition }">
          <!-- open_location_sel_status -->
          <div class="card">
            <simplebar class="card-body" style="max-height: 87.5vh; min-height: 87.5vh">
              <div style="padding-left: 2%; padding-right:2%;">
                <b-row class="col-margin-bot-1 cursor: pointer;">
                  <span class="d-flex justify-content-center font-size-16 btn_back_blue" @click="clearData(),hideTruckTransition()"><i class="ri-arrow-left-line" style="margin-right:5px;" />ย้อนกลับ</span>
                </b-row>
                      
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    AA000999 (กำลังติดตาม)
                  </b-col>
                </b-row>
                <b-row class="col-margin-bot-1">
                  <b-col> 
                    <span class="font-color-blue" style=""> 4ล้อ - ตู้ทึบ </span>
                  </b-col>
                </b-row>

                <b-row class="col-margin-bot-1">
                  <b-col>  14/04/2021 15:50 แขวงบางนาหนือ เขตบางนา กรุงเทพมหานคร </b-col>
                </b-row>

                <b-row class="col-margin-bot-1">
                  <b-col> 14/04/2021 14:50 แขวงบางนาหนือ เขตบางนา กรุงเทพมหานคร </b-col>
                </b-row>
              </div>
            </simplebar>
          </div>
        </div>
      </transition>
      <!-- END TRANSITION TRUCK-->
    </b-row>

    <!-- mark-popup -->
    <div style="display: none;">
      <div id="marker">
        1234 
        <br />
        <button @click="close_popup()">ปิด</button>
      </div>
    </div>
    <!-- MODAL PLAN DETAIL -->
    <PlanDetail ref="plandetail" :obj="{}" />

    <!-- company detail -->
    <ModalCompany ref="modalCompany" :obj="{}" />

    <ModalProof ref="modalProof" :obj="{}" @fin-plan="sendFinPlan" />
  </Layout>
</template>

<style>
@import '../../../../node_modules/ol-layerswitcher/dist/ol-layerswitcher.css';
body {
  overflow: hidden;
}


hr {
    border: 1px solid #e4e4e4;
}

.simplebar-plan-display {
    padding: 20px;
}

.simple-truck-display{
    padding: 20px;
}

/*width: 480px;*/
.location_menu.active {
    position: absolute;
    width:33.33%;
    z-index: 11;
}

#map {
  height: calc(100vh - 115px); /* 100% of the viewport height - navbar height */
}

.location_dash{
    border-left: 2px dashed;
    height: 110%;
    position: absolute;
    left: 36%;
    z-index:-1;
}
.avatar-title {
  /* background-color: #fff !important; */
  background-color: hsla(197, 100%, 89%, 0) !important;
}
/*.activity-wid .activity-list:before {
    content: "";
    border-left: 2px dashed rgba(3, 174, 239, 0.25);
    position: absolute;
    left: 0;
    bottom: 0;
    top: 32px;
}*/
#marker {
  margin-bottom: 30vh;
  width: 550px;
  /* border: 1px solid rgb(216, 216, 216); */
  border-radius: 10px;
  position: relative;
  padding: 0.6em;
  background-color: rgb(255, 255, 255);
}
#marker:before {
  position: absolute;
  content: "";
  top: 100%;
  left: 50%;
  border: solid 15px transparent;
  border-top: solid 15px rgb(255, 255, 255);
  width: 0;
  height: 0;
}
</style>